.integracao_container {
  padding: 5rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  box-sizing: content-box;
  height: 650px;
}
.integracao_container :first-child {
  overflow: hidden !important;
}

.loading_gerar_integrador {
  position: absolute;
  top: 0px;
  right: 150px;
}

.botao_gerador_integrador {
  position: absolute;
  top: 15px;
  right: 15px;
}

.table {
  border-collapse: collapse;
  table-layout: fixed;
  font-family: "Lato", sans-serif;
  overflow: hidden;
  width: 100%;
}

.table-header-row {
  background-color: #eeeeee;

  font-size: 14px;
  font-weight: 300;
  font-weight: bold;

  padding: 15px 5px;
  text-align: center;

  letter-spacing: 3px;
}

.table-cell {
  position: relative;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;

  font-size: 14px;
  font-weight: 400;

  text-align: center;
  letter-spacing: 1px;

  padding-top: 20px;
  padding-bottom: 20px;

  .fa-copy {
    color: $colorPrimary;

    &:hover {
      color: $colorPrimaryHover;
      transform: scale(1.1);
    }
  }
}

.even-row:hover {
  background-color: rgb(165, 218, 165);
  cursor: pointer;
}

.odd-row {
  background: rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: rgb(165, 218, 165);
    cursor: pointer;
  }
}
