.pdfViewerContainer {
  @include respond(tab-land) {
    flex-flow: column wrap;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 2rem;
  }

  .menuContainer,
  .textoContainer {
    @include respond(tab-land) {
      max-width: 100% !important;
    }
  }

  .menuContainer {
    @include respond(tab-land) {
      order: 2;
    }
  }

  .zoomContainer {
    @include respond(tab-land) {
      top: 20px !important;
      margin-left: 18px;
      right: inherit !important;
    }
  }

  .pdfContainer {
    @include respond(tab-land) {
      order: 1;
      flex: inherit;
      width: 100%;
      margin-bottom: 4rem;
    }

    .navPDFContainer {
      @include respond(tab-land) {
        right: 44% !important;
      }
    }
  }
}

// Menu Superior
// @media (min-width: 1374px) and (max-width: 1500px) {
//   .menuHorizontal {
//     nav .start-fiscal,
//     a:nth-child(2):hover ~ .animation {
//       left: 183px;
//     }

//     nav .start-obrigacoes,
//     a:nth-child(3):hover ~ .animation {
//       left: 320px;
//     }

//     nav .start-relatorios,
//     a:nth-child(4):hover ~ .animation {
//       left: 486px;
//     }

//     nav .start-clientes,
//     a:nth-child(5):hover ~ .animation {
//       left: 663px;
//     }

//     nav .start-integrador,
//     a:nth-child(6):hover ~ .animation {
//       left: 805px;
//     }

//     nav {
//       a {
//         margin-right: 0;
//       }
//     }
//   }
// }

.menuHorizontal {
  .scroll-div {
    display: flex;
  }
}

@media (max-width: 1386px) {
  .menuHorizontal {
    .scroll-responsive {
      display: inline;
    }

    .animation {
      display: none;
    }

    .scroll-div {
      overflow-x: auto;
      transition: none !important;
    }

    nav {
      position: relative;
      // padding-top: 10px;

      a {
        display: inline;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 969px) and (max-width: 1386px) {
  .menuHorizontal {
    .scroll-div {
      width: 800px;
    }
  }
}

@media (min-width: 970px) and (max-width: 1143px) {
  .menuHorizontal {
    .scroll-div {
      width: 620px;
    }
  }
}

@media (min-width: 683px) and (max-width: 969px) {
  .menuHorizontal {
    .scroll-div {
      width: 324px;
    }
  }
}

@media (min-width: 500px) and (max-width: 682px) {
  .menuHorizontal {
    .scroll-div {
      width: 140px;
    }
  }
}

@media (min-width: 320px) and (max-width: 499px) {
  .menuHorizontal {
    .scroll-div {
      width: 100%;
    }
    .scroll-responsive {
      display: none;
    }
  }
}


// Menu Inferior
@media (min-width: 320px) and (max-width: 1140px) {
  .menuInferiorContainer.fiscal {
    flex-wrap: wrap;
    margin-bottom: 0 !important;

    .aba {
      flex: 1 0 33%;
      text-align: center;
      margin-bottom: 20px;

      span {
        padding-bottom: 4px;
      }
    }
  }
}
//
