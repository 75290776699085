.container_checkBox {
  .checkBox-input {
    display: none;
  }

  .checkBox-group {
    width: 100%;
    display: block
  }
  .checkBox-label {
    font-size: 1.2rem;
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
  }
  .checkBox-button {
    height: 1.5rem;
    width: 1.5rem;
    border: 0.35rem solid #1abc9c;
    border-radius: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;

    &::after {
      content: "";
      display: block;
      height: 0.5rem;
      width: 0.5rem;
      background-color: #1abc9c;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  .checkBox-input:checked + .checkBox-label .checkBox-button::after {
    opacity: 1;
  }
}
