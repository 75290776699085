.separadorContainer > div {
  margin-bottom: 2rem;
}

.separadorContainer {

  padding: 0 1rem;

  .separadorTable {
    margin: 0 auto;
    border: 1px solid #707070;
    border-collapse: collapse;
    min-width: 220px;
    font-size: 12pt;

    thead th, tbody td, tfoot th {
      padding: 1rem;
      text-align: center;
    }

    thead th, tfoot th {
      //color: #000;
      font-weight: bold;
    }

    thead {
      border-bottom: 1px solid #707070;
    }

    tbody {

      tr {
        border-bottom: 1px solid #707070;

        td {
          //color: #757575;
          font-weight: normal;
        }
      }
    }
  }

  .separador_buttons_container {
    display: flex;
    margin-top: 5px;

    button {
      font-size: 13pt;
      margin-right: 20px;
    }
  }

}
