@import url(https://fonts.googleapis.com/css2?family=Courier+Prime&family=Cutive+Mono&display=swap);

@import "abstract/functions.scss";
@import "abstract/variables.scss";
@import "abstract/mixins.scss";
@import "abstract/animation.scss";
@import "abstract/utilities.scss";

@import "base/base.scss";
@import "base/typography.scss";
@import "base/mediaQueries.scss";

@import "components/toolbar.scss";
@import "components/menuInferior.scss";
@import "components/select.scss";
@import "components/card.scss";
@import "components/cardwithtabs.scss";
@import "components/excelgrid.scss";
@import "components/modal.scss";
@import "components/tabela.scss";
@import "components/inputForms.scss";
@import "components/input.scss";
@import "components/reactToastify.scss";
@import "components/button.scss";
@import "components/tabelaReact.scss";
@import "components/checkbox.scss";

@import "containers/integrador/integrador.scss";
@import "containers/integrador/children/planilha.scss";
@import "containers/integrador/children/integracao.scss";
@import "containers/integrador/children/configuracoes.scss";
@import "containers/integrador/children/analise.scss";
@import "containers/integrador/children/opcoes.scss";
@import "containers/integrador/children/separador.scss";
