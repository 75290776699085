.react-tabs {
  -webkit-tap-highlight-color: transparent;

  display: block;
  margin: 0 1% 0% 1%;
}

.react-tabs__tab-list {
  margin: 0 0 0 0;
  padding: 0;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 18px;
  color: #ffffff;

  width: 95%;

  & > li {
    padding: 10px 10px;
    outline: none;
  }
  & > li:nth-child(even) {
    margin: 0 20px;
  }
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  margin-right: 2px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  //width: 140px;
  width: auto;

  text-align: center;
  background-color: $tabsNaoSelecionadoBg;
  font-size: $tabsNaoSelecionadoTamanho;
  font-family: $tabsNaoSelecionadoFonte;
  font-weight: $tabsNaoSelecionadoPeso;
  padding: 15px;
  letter-spacing: 3px;
  height: auto;
  //min-height: 70px;
}

.react-tabs__tab--selected {
  font-family: "Lato", sans-serif !important;
  font-weight: 900;
  font-size: 18px;
  color: $tabsSelecionadoCor !important;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: not-allowed;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  background-color: white;

  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
