*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 100%; //1 rem = 10px; 10px/16px = 62.5%
  @include respond(tab-land) {
    font-size: 56.25%; //1 rem = 9px; 9px/16px = 56.25%
  }
  @include respond(tab-port) {
    font-size: 50%; //1 rem = 8px; 8px/16px = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; //1 rem = 12px; 12px/16px = 75%
  }
}

body {
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include respond(tab-port) {
    padding: 0;
  }
}

// Reavaliar Depois

.container {
  max-width: 1140px;
  width: 100%;
  margin: auto;
}

.containerViewport {
  background: white;
  min-height: 90vh;
  padding-top: 10px;
}

.meioPrincipal {
  background: white;
  display: flex;
  flex-direction: column;
  // min-height: inherit;
  // height: 120vh;
  height: 100%;
}
.meioPrincipal > div {
  margin: 1.5rem; /* and that, will result in a 20px gap */
}
