.card {
  position: relative;
  margin: 0 1.0% 1.5% 1.0%;
  border-radius: 10px;

  &__icone_container {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;

    &--icone {
      margin: 0 1.4rem 1rem 0;

      &:hover {
        transform: scale(1.2);
        color: green;
        cursor: pointer;
      }
    }

    &--filtro {
      margin-top: 5px
    }

    &--mais {
      display: flex;
      align-items: center;
    }


  }

  &__container {
    padding-bottom: 4rem;

    &--minimizado {
      display: none;
    }
  }

}