@mixin boxShadowDark() {
  box-shadow: 0 1.5rem 4rem rgba($color-black, 0.15);
}

@mixin centerElement() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER

/*$breakpoint argument choices:

- phone
- tab-port
- tab-land
- big-desktop

*/

@mixin respond($breakpoint) {
  @if $breakpoint == phone-small {
    @media (max-width: 25em) {
      //400px / 16 = 25em
      @content;
    }
  }

  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      //1200px
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      //1800px
      @content;
    }
  }
}
