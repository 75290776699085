.temporario {
   position: relative;
   top: -47px;
   right: -105px;
   color: $menuSuperiorNaoSelecionadoCor;
   font-weight: bold;
   text-decoration: none;
   font-size: 13pt;
   &:hover {
      color: $colorPrimary;
      text-decoration: none;
   }

   svg {
      position: relative;
      top: 2.5px;
      right: 3px;
      font-size: 17pt;
   }
}

.containerImgPerfil {
   display: inline-block;
   cursor: pointer;
   padding-top: 32px;
   padding-bottom: 23px;
   padding-right: 20px;
   text-align: center;
   position: relative;

   .arrow {
      border: solid $colorPrimary;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
   }
}

.darkLightToggle {
   margin-right: 20px;
   padding-top: 36px;
   padding-bottom: 23px;
   float: right;
}

.logoToolbar {
   //content: url('../../assets/imagens/logokorok.jpg');
  //  width: 30%;
  //  height: 30%;
   margin-left: 20pt;
   cursor: pointer;
   img {
      width: 30px;
   }
}

.iconBtn {
   color: black;
   padding-top: 30px;
   padding-bottom: 30px;
}

.iconAbriModal {
   cursor: pointer;
   margin-left: 16px;
}

.txtAlignCenter {
   text-align: center;
}

.inputfile {
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}

.loadImg {
   cursor: pointer;
}

/*
    Imagem Perfil e menu
*/
.containerPerfil {
   position: relative;

   width: 15%;
   display: flex;
   flex-direction: row-reverse;

   .react-toggle-track-x,
   .react-toggle-track-check {
      visibility: hidden;
   }

   .react-toggle--checked .react-toggle-track {
      background-color: rgb(161, 160, 160);
   }

   .react-toggle--checked:hover:not(.react-toggle--disabled)
      .react-toggle-track {
      background-color: rgb(161, 160, 160);
   }

   .react-toggle--checked .react-toggle-thumb {
      border-color: rgb(161, 160, 160);
   }
}

.containerPerfilExibido {
   position: absolute;
   display: block !important;
   visibility: visible !important;
   background: white;
   border-radius: 7px;
   right: 0px;
   top: 66px;
   z-index: 161;
   /*z-index maior que da tabela do integrador*/
   text-align: left;
   border-style: solid;
   border-width: 1px;
   border-color: #b5b5b5;
}

.containerMenu {
   position: absolute;
   top: 92%;
   right: -15px;
   z-index: 2000;

   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   align-items: center;

   padding: 1rem;

   & a {
      text-decoration: none; /* no underline */
      &:hover {
         color: rgb(96, 168, 23);
      }
   }

   &__links {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      margin-bottom: 0rem;

      border-top: #b5b5b5 1px solid;
      border-bottom: #b5b5b5 1px solid;
      height: 7rem;
      width: 20rem;
   }
}

.imgPerfil {
   height: 40px;
   width: 40px;
   border-radius: 50%;
}

.containerFotoMaior {
   text-align: center;
}

.imgPerfilMaior {
   height: 120px;
   width: 120px;
   border-radius: 50%;
   margin: 0px 35px 0px 35px;
}

.semImg {
   height: 120px;
   width: 120px;
   border-radius: 50%;
   margin: 0px auto 10px auto;
   background: grey;
   text-align: center;
   padding-top: 34px;
   padding-bottom: 52px;
   text-align: center;
   font-size: 40px;
}

.semImgPequeno {
   height: 40px;
   width: 40px;
   border-radius: 50%;
   background: grey;
   text-align: center;
   padding-top: 10px;
   padding-bottom: 10px;
}

.imgPerfilMaior {
   transition-property: opacity;
   transition-duration: 100ms;
   transition-timing-function: linear;
   transition-delay: 0s;
}

.identificadorUsuario {
   color: inherit;
   text-align: center;
   margin-top: 10px;
   margin-bottom: 20px;
   cursor: default;
   font-weight: 900;
}

.containerAlterarFoto:hover .divAlterar {
   position: absolute;
   right: 0px;
   left: 0px;
   top: 36%;
   color: #353535e8;
   z-index: 20;
   font-size: 25px;
   display: block;
   visibility: visible;
}

.containerAlterarFoto:hover .imgPerfilMaior {
   opacity: 0.7;
}

.divAlterar {
   display: none;
   visibility: hidden;
   transition-property: visibility;
   transition-duration: 100ms;
   transition-timing-function: linear;
   transition-delay: 0s;
}

.containerAlterarFoto {
   position: relative;
}

.devider {
   height: 1px;
   width: 100%;
   background: #828282;
}

.linkMenu {
   padding: 12px 30px 12px 16px;
   white-space: nowrap;
   cursor: pointer;
}

.linkMenu:hover {
   background: #eeeeee;
}

.espacamentoTopo {
   margin-top: 4vw;
}

.sair {
   padding: 30px 30px 30px 16px;
}

/*
    Drowdown links
*/
@media (min-width: 701px) {
   .ContainerLink {
      position: relative;
      cursor: pointer;
      white-space: nowrap;
      color: grey;
      transition-property: color;
      transition-duration: 100ms;
      transition-timing-function: linear;
      transition-delay: 0s;
      min-width: 215px;
      text-align: center;
   }

   .ContainerLink:hover {
      color: black;
   }

   .ContainerLink:hover .containerDropdown {
      position: absolute;
      display: block;
      visibility: visible;
      background: white;
      border-radius: 7px;
      z-index: 2;
      width: 100%;
      border-style: solid;
      border-width: 0.5px;
      border-color: grey;
      text-align: left;
   }

   .linhaVerde {
      width: 100%;
      height: 3px;
      background: green;
      position: absolute;
      bottom: 3px;
      visibility: hidden;
   }

   .ContainerLink:hover .linhaVerde {
      visibility: visible;
   }

   .containerDropdown {
      position: absolute;
      display: block;
      visibility: hidden;
      margin-top: -2px;
   }

   .espacamentoTopo {
      margin-top: 1vw;
   }

   .espacamentoRodape {
      margin-top: 1vw;
   }

   .linkSecundario {
      color: grey;
      margin: 15px 10px 0px 15px;
      white-space: nowrap;
   }

   .linkSecundario:hover {
      color: #000000;
   }

   .linkToolbar {
      padding-bottom: 6px;

      &:hover {
         border-bottom: green 3px solid;
      }
   }
}

@media (max-width: 700px) {
   .link {
      margin: 0px 20px 0px 20px;
      color: white;
   }
}

.toolbar {
   background: white;
   height: 65px;
}

@media (min-width: 701px) {
   .btnAbrirDrawer {
      display: none;
   }

   // .containerHeader {
   //   height: 100%;
   //   display: flex;
   //   height: 100%;
   //   align-items: center;
   // }
}

@media (max-width: 700px) {
   .btnAbrirDrawer {
      display: inline-flex;
      height: 100%;
      align-items: center;
      color: white;
   }

   .containerBtn {
      height: 100%;
      display: block;
   }

  //  .containerHeader {
  //     display: none;
  //     // visibility: hidden;
  //     display: flex;
  //     height: 100%;
  //     align-items: center;
  //  }
}

///Testim
.scroll-div::-webkit-scrollbar {
   width: 10px;
   display: none !important;
}

.scroll-responsive {
   position: absolute;
   top: 15.5px;
   display: none;
   background: transparent;
   border: 0;
   cursor: pointer;
   font-size: 13pt;
   color: #999999;
   &:focus {
      outline: none !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
   }
}

.scroll-responsive.left {
   left: -38px;
   top: 7px;
}

.scroll-responsive.right {
   right: -48px;
   top: 7px;
}

.containerHeader {
   display: flex;
   height: 100%;
   align-items: center;
}

.menuHorizontal {
   width: 100%;
  //  margin-top: 1rem;
  //  margin-left: 2rem;
   display: flex;
   justify-content: space-around;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;

   & a {
      text-decoration: none;
      letter-spacing: 3px;
      margin-right: 30pt;
      padding-bottom: 7px;

      &:last-child {
         margin-right: 0;
      }

      &:focus {
         color: $menuSuperiorSelecionadoCor;
      }
      &:active {
         color: $menuSuperiorSelecionadoCor;
      }
      &:hover {
         color: $menuSuperiorSelecionadoCor;
      }
   }
}

nav {
   position: relative;
  //  height: 50px;
   background-color: white;
   border-radius: 8px;
   font-size: 0;
}

nav a {
   //line-height: 10px;
   font-size: $menuSuperiorNaoSelecionadoTamanho;
   font-family: $menuSuperiorNaoSelecionadoFonte;
   font-weight: $menuSuperiorNaoSelecionadoPeso;
   color: $menuSuperiorNaoSelecionadoCor;
   display: inline-block;
   z-index: 1;
   text-decoration: none;
   text-transform: uppercase;
   text-align: center;
   cursor: pointer;
}

// a:nth-child(1) {
//    width: 160px;
// }

.menu-active {
  border-bottom: 4px solid #1abc9c;
}

// a:nth-child(2) {
//   width: 160px;
// }
// a:nth-child(3) {
//   width: 160px;
// }
// a:nth-child(4) {
//   width: 160px;
// }
// a:nth-child(5) {
//   width: 160px;
// }
// a:nth-child(6) {
//   width: 160px;
// }
// a:nth-child(7) {
//   width: 160px;
// }
// nav .start-documentos,
// a:nth-child(1):hover ~ .animation {
//   width: 155px;
//   left: 0;
// }
// nav .start-fiscal,
// a:nth-child(2):hover ~ .animation {
//   width: 105px;
//   left: 210px;
// }
// nav .start-obrigacoes,
// a:nth-child(3):hover ~ .animation {
//   width: 160px;
//   left: 370px;
// }
// nav .start-relatorios,
// a:nth-child(4):hover ~ .animation {
//   width: 150px;
//   left: 564px;
// }
// nav .start-clientes,
// a:nth-child(5):hover ~ .animation {
//   width: 114px;
//   left: 770px;
// }
// nav .start-integrador,
// a:nth-child(6):hover ~ .animation {
//   width: 150px;
//   left: 938px;
// }

// nav .start-relatorios#acesso-cliente,
// .start-relatorios#acesso-cliente:hover ~ .animation {
//   width: 160px;
//   left: 559px;
// }
