.u-center-text {
  text-align: center !important;
}

.u-margin-botom-big {
  margin-bottom: 8rem !important;
}

.u-margin-botom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-botom-small {
  margin-bottom: 1.2rem !important;
}

.u-margin-top-small {
  margin-top: 2rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 12rem !important;
}

//Com certeza reavaliar depois

.mr-1 {
  margin-right: 3px;
}

.mr-2 {
  margin-right: 6px;
}

.mr-3 {
  margin-right: 9px;
}

.mr-4 {
  margin-right: 12px;
}

.pr-1 {
  padding-right: 3px;
}

.pr-2 {
  padding-right: 6px;
}

.pr-3 {
  padding-right: 9px;
}

.pr-4 {
  padding-right: 12px;
}

.ml-1 {
  margin-left: 3px;
}

.ml-2 {
  margin-left: 6px;
}

.ml-3 {
  margin-left: 9px;
}

.ml-4 {
  margin-left: 12px;
}

.pl-1 {
  padding-left: 3px;
}

.pl-2 {
  padding-left: 6px;
}

.pl-3 {
  padding-left: 9px;
}

.pl-4 {
  padding-left: 12px;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.txt-align-center {
  text-align: center;
}

.centralizarNoMeio {
  margin-top: 30vh;
}
