.planilhaContainer {
  button {
    position: sticky;
    padding: 1em;
    margin-right: 1rem;

    &:hover {
      border: 2px solid red;
      cursor: pointer;
      color: red;
    }
  }

  display: flex;
  align-content: center;
  justify-content: center;
  padding: 3rem 0 3rem 0;

  &--excel {
    height: 60rem;

    width: 86rem;
  }

  & td {
    z-index: 0 !important;
  }
}

.espelhoContainerPessoal {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1rem 0 1rem 0;

  &--excel {
    height: 60rem;
  }
  table {
    width: 100%;
    table-layout: fixed;
  }

  & td {
    z-index: 0 !important;
  }

  & .resizable tr {
    word-break: break-all;
    word-wrap: break-word;
    line-height: 2;
  }

  & .resizable tr td {
    word-break: break-all;
    word-wrap: break-word;
  }
}

.jexcel {
  width: 80vw;

  tbody {
    td {
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.icone {
  position: absolute;
  top: 5%;
  right: 2%;
  border: none;

  &:hover {
    color: green;
    transform: scale(1.2);
  }
}
