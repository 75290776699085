.integradorAnaliseContainer {
  //display: flex;
  padding: 1.1rem; /* this */

  .integradorInfoFrenteModal {
    // position: absolute;
    // top: 85px;
    border: 1px solid #babdbe;
    text-align: center;
    border-left: 8px solid $colorPrimary;

    & > span {
      margin: 0 5rem;
      padding: 25px 0;
      display: inline-block;
    }
  }

  .nenhuma_baixa {
    padding: 0.85rem 2rem 2.7rem 2rem;
    font-weight: bold;
  }

  .input_container_padding, .notasDescobertas_container {
    padding: 0 2rem;

    .dd_wrapper, .input_container {
      margin-bottom: 1.5rem;
    }

    .salvar_filtro, .lancar-btn {

      margin-top: 1rem;
      margin-bottom: 2.5rem;
      flex-direction: initial;

      button {
        font-size: 13pt;
      }
    }
  }
}

.salvar-baixas-btn {

  padding-left: 24px;
  // margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  flex-direction: initial;

  button {
    font-size: 13pt;
  }
}

.resultados_container {
  width: 100%;
  // height: 50rem;
  overflow-y: auto;
  padding-top: 2rem;
}

.container_notasAbertas {
  margin-top: 2rem;
  margin-bottom: 4rem;

  .dd_wrapper {
    width: 90%;
    margin-left: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }
  .input_container {
    width: 90%;
    margin-left: 2rem;
  }
}

.analiseNotasContainer {
  border: 1px dashed #babdbe;
  margin: 2rem;
  position: relative;

  transition: all ease-out 0.5s;

  &:hover {
    transform: translateY(-10px);
    border: 1px solid #babdbe;
    cursor: pointer;
  }

  &:not(last-child) {
    margin-bottom: 4rem;
  }

  &__headBox, &__headBox--green, &__headBox--yellow, &__headBox--red {
    padding: 5px 25px 5px 25px;
    position: absolute;
    border: 1px solid #babdbe;
    border-radius: 50px;
    top: -12px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #e0e0e0;
    color: #000;
    font-size: 11pt;

    &--green, &--yellow, &--red {
      color: #fff;
    }

    &--green {
      background-color: #239023;
    }

    &--yellow {
      background-color: #b4b400;
    }

    &--red {
      background-color: #e10000;
    }
  }

  &__circulo {
    display: inline-block;

    position: absolute;
    top: 12px;
    right: 12px;

    border-radius: 50%;
    border: 1px solid black;

    height: 30px;
    width: 30px;

    & svg {
      @include centerElement();
    }

    &--vermelho {
      background-color: red;
    }
    &--amarelo {
      background-color: yellow;
    }
    &--verde {
      background-color: green;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 2.5rem 2rem 2rem 2rem;

    &--atributo {
      flex-grow: 1;
      flex: 1 0 25%;
      margin: 0.5rem 0;
    }
  }

  &__aviso {
    display: none;
    padding: 0.5rem;
    text-align: center;

    p {
      font-weight: 600;
    }

    &--btns {
      padding: 1rem;
      // justify-content: space-around;
      // display: flex;
      div {
        display: inline-block;

        button {
          margin: 0 1rem;
          font-size: 12pt;
        }
      }
    }
  }

  &:hover &__aviso {
    display: block;
  }

  &__baixaSelecionada {
    border: 2px ridge #babdbe;
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3rem;

    margin: auto;
    height: 20px; /*requires explicit height*/
  }
}

.analiseNotaEscolhida {
  border: 1px ridge #DCDCDC;
  margin: 3rem 2rem;
  flex: 0 0 40%;
  align-self: center;
  position: relative;

  &:not(last-child) {
    margin-bottom: 4rem;
  }

  &__headBox, &__headBox--green, &__headBox--yellow, &__headBox--red {
    padding: 5px 25px 5px 25px;
    position: absolute;
    border: 1px solid #babdbe;
    border-radius: 50px;
    top: -12px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #e0e0e0;
    color: #000;
    font-size: 11pt;

    &--green, &--yellow, &--red {
      color: #fff;
    }

    &--green {
      background-color: #239023;
    }

    &--yellow {
      background-color: #b4b400;
    }

    &--red {
      background-color: #e10000;
    }
  }

  &__circulo {
    display: inline-block;

    position: absolute;
    top: 12px;
    right: 12px;

    border-radius: 50%;
    border: 1px solid black;

    height: 30px;
    width: 30px;

    & svg {
      @include centerElement();
    }

    &--vermelho {
      background-color: red;
    }
    &--amarelo {
      background-color: yellow;
    }
    &--verde {
      background-color: green;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 3rem 2rem 2rem 2rem;

    &--atributo {
      flex-grow: 1;
      flex: 1 0 25%;
      margin: 0.5rem 0;
    }
  }

  &__aviso {
    display: none;
    padding: 0.5rem;
    text-align: center;
    &--btns {
      padding: 1rem;
      justify-content: space-around;
      display: flex;
    }
  }

  &:hover &__aviso {
    display: block;
  }

  &__baixaSelecionada {
    border: 2px ridge #babdbe;
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3rem;

    margin: auto;
    height: 20px; /*requires explicit height*/
  }

  .botaoReact__acao {
    font-size: 12pt;
    margin-left: 2rem;
    margin-bottom: 2.5rem;
  }
}

.lancamentoContabilContainer {
  display: flex;
  flex-direction: column;

  // & > div:not(:last-child) {
  //   margin-bottom: 5rem;
  // }

  & > div:last-child {
    display: flex;
    flex-direction: row-reverse;
  }
}

.integradorBaixasContainer_table {
  width: 100%;
  margin-top: 3rem;
  // margin-right: 1rem;
  // margin-left: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #DCDCDC;
  border-top: 0;
}

.integradorBaixasContainer_tabs {
  display: flex;
  width: 100.15%;
  margin-left: -1px;
  //background-color: #FFF;
  border-bottom: 1px solid #DCDCDC;
  margin-bottom: 20px;
  // flex-wrap: wrap;
  // justify-content: space-around;
  // align-items: stretch;
  // margin-bottom: 2.5rem;
  
  .integradorBaixa_button_tabs {
    padding: 1rem;
    width: 228px;
    border: 0;
    font-size: 12pt;
    text-align: center;
    padding-right: 29px;
    border: 0;
    //padding: 0px 54px;
    -webkit-clip-path: polygon(0 0, 82% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 82% 0, 100% 100%, 0 100%);
    font-weight: bold;
    cursor: pointer;
    //color: #808080;

    &:focus {
      border: 0;
      outline: 0;
    }
  
    &.active {
      background-color: #1ABC9C;
      color: #FFF;
      border: 0;
    }
  }

}

.counter_valorTotal {
  margin: 2rem;
  font-size: 16pt;
  font-weight: bold;
  text-align: end;
}

.notasSelecionadas_container {
  margin: 1rem;
  font-size: x-large;
  text-align: end;
}

// .boxDeNotas {
//   border: 1px solid #DCDCDC;
//   border-left: 8px solid $colorPrimary;
//   padding: 1rem 0;
//   margin-bottom: 1.5rem;
//   display: flex;
//   & span {
//     //margin-left: 6rem;

//     & .fa-times {
//       color: red;

//       &:hover {
//         transform: scale(1.5);
//       }
//     }
//   }
//   & p {
//     display: inline;
//     &.info {
//       color: rgb(190, 68, 68);
//       flex-grow: 1;
//       text-align: center;
//     }
//     &.dados {
//       color: black;
//       margin-right: 1.5rem;
//       margin-left: 1.5rem;
//     }
//   }
// }

.boxDeNotas {
  border: 1px solid #DCDCDC;
  border-left: 8px solid #117964;
  padding: 1rem 0;
  margin: 0 1.15rem;
  margin-bottom: 1.5rem;
  display: flex;

  .info {
    flex-grow: 1;
    text-align: center;
    font-size: 13pt;
    font-weight: bold;
    color: #757575;
    line-height: 25px;

    p {
      display: inline;
      font-weight: normal;
    }

    .divisoria {
      margin: 0 3rem;
      font-weight: bold;
    }
  }

  .icon-container {

    position: relative;
    left: -14px;
    
    .fa-times {
      cursor: pointer;
      color: red;
      &:hover {transform: scale(1.5);}
    }
  }

}