@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700");

$base-spacing-unit: 24px;
$half-spacing-unit: $base-spacing-unit / 2;

$color-alpha: #1772ff;
$color-form-highlight: #eeeeee;

.opcoes_container {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  padding: 1.6rem;
  margin-right: auto;
  margin-left: auto;

  justify-content: center;
  align-items: center;

  body {
    padding: $base-spacing-unit;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  table {
    width: 100%;
    border: 1px solid $color-form-highlight;
  }

  .table-header {
    color: white;

    width: 100%;
    background: #000;
    padding: ($half-spacing-unit * 1.5) 0;
  }

  .table-row {
    width: 100%;
    padding: ($half-spacing-unit * 1.5) 0;

    &:nth-of-type(odd) {
      background: $color-form-highlight;
    }
  }

  .table-data,
  .header__item {
    padding: 2rem;
    text-align: center;
  }

  .header__item {
    text-transform: uppercase;
  }

  .filter__link {
    color: white;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-left: $base-spacing-unit;
    padding-right: $base-spacing-unit;

    &::after {
      content: "";
      position: absolute;
      right: -($half-spacing-unit * 1.5);
      color: white;
      font-size: $half-spacing-unit;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .fa-pencil-alt,
  .fa-check {
    transition: transform 0.2s; /* Animation */
    cursor: pointer;
    &:hover {
      color: green;
      transform: scale(1.5);
    }
  }

  .fa-trash,
  .fa-times {
    transition: transform 0.2s; /* Animation */
    cursor: pointer;
    &:hover {
      color: red;
      transform: scale(1.5);
    }
  }

  input {
    width: 100%;
    padding-left: 0.5rem;
  }

  .alerta {
    border: 1px solid rgba(241, 142, 6, 0.8);
    border-left: 4px solid #ffb103;
    background-color: rgba(241, 196, 15, 0.3);
    box-shadow: 0px 0px 2px #ffb103;
    //color: #000;
    //text-shadow: 1px 1px #fff;
    padding: 0.3rem 0.7rem;
    font-size: 10pt;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 250px;
    line-height: 0;

    svg {
      color: #ffb103;
      -webkit-animation: piscar 2s infinite both;
      animation: piscar 2s infinite both;
      margin-right: 10px;
      font-size: 11pt;
    }
  }

  .dd_wrapper,
  .input_container {
    margin-bottom: 10px;
  }

  .container_checkBox {
    margin-top: 15px;
  }

  .tabelaReact__Container {
    padding: 0;
    margin-top: 40px;
  }

  .botaoReact {
    font-size: 11pt;
    margin-top: 8px;
    margin-bottom: 10px;
  }

  .modal_container {

    .modal_container__content {
      padding: 1rem 0.8rem;
    }

    .checkbox_historico {

      margin-top: 10px;

      label:first-child {
        font-weight: 400;
        letter-spacing: 2px;
        font-family: "Lato-Regular", sans-serif;
        font-size: 12pt;
      }
    }

    .editarOpcao_botoes {

      margin-top: 18px;

      div:first-child, div:last-child {
        display: inline-block;
      }

      div:last-child {
        margin-left: 15px;
      }
    }

    .historico_com_botao_adicionar {

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      .dd_wrapper, .input_container {
        flex: auto;
        flex-basis: 47%;
        flex-grow: 1;
      }

      .historico_plus {
        margin-left: 16px;
        position: relative;
        top: -10px;

        .botaoReact {
          padding: 0.87rem 1.6rem;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

  }
}

@-webkit-keyframes piscar {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes piscar {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
