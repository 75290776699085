.botaoReact {
  font-family: $botaoFonte;
  font-size: $botaoTamanho;
  color: $botaoCor;
  letter-spacing: $botaoEspacamentoLetras;
  padding: $botaoPadding;
  border-radius: 3pt;
  outline: none;
  font-weight: $botaoPeso;
  &:hover,
  :focus {
    outline: none;
    cursor: pointer;
  }

  &:active {
    transform: translateY(2pt);
  }

  &__acao {
    background-color: $botaoAcaoBackground;
    border: 1px solid $botaoAcaoBackground;
    &:hover {
      background-color: $botaoAcaoBackgroundHover;
    }
  }
  &__cancelar {
    background-color: $botaoCancelarBackground;
    border: 1px solid $botaoCancelarBackground;
    &:hover {
      background-color: $botaoCancelarBackgroundHover;
    }
  }
  &__deletar {
    background-color: $botaoDeletarBackground;
    border: 1px solid $botaoDeletarBackground;
    &:hover {
      background-color: $botaoDeletarBackgroundHover;
    }
  }
}
