.tabelaReact {
  &__Container {
    padding: $tabelaContainerPadding;
  }

  &__searchSelectContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .tabelaReact__SearchBox {
      .input_container {
        border-bottom: none;
        display: inline-flex;
        margin-right: 0;

        &__label {
          text-align: left;
          font-size: small;
          font-weight: bold;
          padding: 10px 5px 0 0;
        }

        &__wrapper--input {
          height: 35px;
          padding: 3px 10px 3px 10px;
          border: 1px solid $tabelaBordaCor;
          transition: none;
          &:focus {
            outline: 1;
            transition: none;
            box-shadow: 0 0 0 0.2rem $colorPrimary;
          }
        }
      }
    }

    .tabelaReact__selectMostrarElementos {
      .dd_wrapper {
        .title {
          word-spacing: 65px;
          text-align: left;
          font-size: small;
          font-weight: bold;
        }
      }

      .react-select-container {
        width: 62px;
        position: absolute;
        top: -3px;
        left: 66px;
        background-color: white;
        font-size: small;
      }

      .react-select__dropdown-indicator {
        padding: 2px;
      }
    }
  }

  &__tabelaComponente {
    width: 100%;
    margin: $tabelaMargin;
    font-size: 10.5pt;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed;

    &__colunaComponente th,
    &__bodyComponente td {
      padding: $tabelaPadding;
      word-break: break-word;
    }

    &__colunaComponente,
    &__bodyComponente {
      border: 1px solid;
    }

    &__colunaComponente {
      th {
        min-width: 150px;
        position: relative;

        svg {
          color: rgba(0, 0, 0, 0.3);
        }

        div {
          position: absolute;
          right: 12px;
          top: 4px;
        }

        button {
          display: inherit;
          border: 0;
          padding: 0;
          margin: 0;
          background-color: transparent;
          cursor: pointer;
          margin-bottom: -7px;

          &:focus {
            outline: 0;

            svg {
              color: #000;
            }
          }
        }
      }
    }

    &__bodyComponente {
      tr {
        td {
          border-bottom: 1px solid $tabelaBordaCor;
        }

        td.tabelaReact__icones {
          background-color: transparent !important;
          border: 0;

          svg {
            cursor: pointer;
          }
        }
      }

      &_tr {
        cursor: pointer;
      }
      /*
      &_td {
        background-color: $colorPrimary !important;
      }
      */
    }
  }

  &__paginacaoContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .tabelaReact__PaginacaoRange {
      font-size: small;
      font-weight: bold;
    }

    .tabelaReact__PaginacaoListagem {
      font-size: small;
      display: flex;

      .botao__paginacao.disabled {
        cursor: not-allowed;
        color: $tabelaPaginacaoIconeDisabledCor;
      }

      svg {
        transform: scale(1.3);
      }

      ul {
        list-style: none;
        display: flex;

        .active {
          background-color: $colorPrimary;
          color: #fff;
          border-radius: 0.125rem;
          -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
          -webkit-transition: all 0.2s linear;
          transition: all 0.2s linear;
        }
      }

      ul li,
      .botao__paginacao {
        padding: $tabelaPaginacaoItensPadding;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        color: $tabelaPaginacaoCor;
      }
    }
  }
}
