@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap");

/**** Colors ****/
$colorPrimary: #1abc9c;
$colorPrimaryHover: #157763;

/**** Menu Superior ****/
//Não Selecionado
$menuSuperiorNaoSelecionadoFonte: "Lato", sans-serif;
$menuSuperiorNaoSelecionadoTamanho: 11pt;
$menuSuperiorNaoSelecionadoPeso: 800;
$menuSuperiorNaoSelecionadoCor: #999999;

//Selecionado
$menuSuperiorSelecionadoCor: #000000;

/**** Menu Inferior ****/
//Não Selecionado
$menuInferiorNaoSelecionadoFonte: "Lato", sans-serif;
$menuInferiorNaoSelecionadoTamanho: 12pt;
$menuInferiorNaoSelecionadoPeso: 800;
$menuInferiorNaoSelecionadoCor: #999999;

//Selecionado
$menuInferiorSelecionadoCor: #000000;

/**** Input e Select ****/
$tituloFonte: "Lato-Regular", sans-serif;
$tituloTamanho: 12pt;
$tituloCor: #4d4d4d;

//Texto do input
$textoFonteInput: "Lato-Light", sans-serif;
$textoTamanhoInput: 12pt;
$textoCorInput: #999999;

//Texto e Background do Select (Selecionado)
$textoFonteSelectSelecionado: "Lato-Light", sans-serif;
$textoTamanhoSelectSelecionado: 12pt;
$textoCorSelectSelecionado: #ffffff;
$backgroundSelectSelecionado: $colorPrimary;
$backgroundSelectSelecionadoFocado: #1abc9c62;

//Texto e Background do Select (Não Selecionado)
$textoFonteSelectNaoSelecionado: "Lato-Light", sans-serif;
$textoTamanhoSelectNaoSelecionado: 12pt;
$textoCorSelectNaoSelecionado: #666666;
$backgroundSelectNaoSelecionado: #f2f2f2;

/**** Botões ****/
//Botão
$botaoFonte: "Lato", sans-serif;
$botaoPadding: 5pt 15pt 5pt 15pt;
$botaoTamanho: 16pt;
$botaoPeso: 800;
$botaoCor: #ffffff;
$botaoEspacamentoLetras: 1pt;

// Botão Deletar
$botaoDeletarBackground: #f70000;
$botaoDeletarBackgroundHover: #f7000085;

// Botão Cancelar
$botaoCancelarBackground: #000000;
$botaoCancelarBackgroundHover: #383434;

// Botão Acao
$botaoAcaoBackground: $colorPrimary;
$botaoAcaoBackgroundHover: $colorPrimaryHover;

/**** Modal ****/
//Container
$modalContainerMaxWidth: 90%;
$modalContainerMinWidth: 50%;
//$modalContainerPadding: 25pt 30pt 35pt 30pt;
$modalContainerPadding: 2rem 1rem 1rem 1rem;
$modalContainerBorderRadius: 10px;
$modalContainerMarginTop: 2%;

//Titulo
$modalTituloFonte: "Lato", sans-serif;
$modalTituloTamanho: 18pt;
$modalTituloPeso: 800;
$modalTituloCor: $colorPrimary;
$modalTituloEspacamentoLetras: 1pt;
$modalTituloMarginBottom: 1rem;

/**** Card With Tabs****/
//Não Selecionado
$tabsNaoSelecionadoFonte: "Lato", sans-serif;
$tabsNaoSelecionadoTamanho: 12pt;
$tabsNaoSelecionadoPeso: 800;
$tabsNaoSelecionadoBg: $colorPrimary;

//Selecionado
$tabsSelecionadoCor: $colorPrimary;

/**** Tabela React ****/
//Tabela
$tabelaBordaCor: #dee2e6;
$tabelaPadding: 0.75rem;
$tabelaContainerPadding: 1rem 2rem;
$tabelaMargin: 1.6rem 0;

//Paginacao
$tabelaPaginacaoItensPadding: 0.6rem 0.7rem;
$tabelaPaginacaoCor: #212529;
$tabelaPaginacaoIconeDisabledCor: #868e96;

