.integrador_container {
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;

  .dd_wrapper {
    margin: 1rem;
  }
}

.integrador_tabs {

  .react-tabs__tab-list {

    li {
      -webkit-clip-path: polygon(0 0,82% 0,100% 100%,0 100%);
      clip-path: polygon(0 0,82% 0,100% 100%,0 100%);
      width: 160px;
      border-radius: 0;
      margin: 0 1px 0px 0px;
      padding: 12px 12px 12px 0px;
      color: #999999;
      letter-spacing: 2px;
      transition: all 0.20s linear;
    }

    .react-tabs__tab--selected {
      font-size: 12pt;
      color: #FFF !important;
      background-color: #1ABC9C !important;
      transition: none !important;
    }
  }

  .react-tabs__tab-panel {
    border-radius: 0;
  }
}
