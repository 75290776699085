input:disabled {
  background: #D3D3D3 !important;
  color: #000 !important;
  //cursor: not-allowed;
}

.input_container {
  width: 100%;
  display: inline-block;
  //
  position: relative;
  display: inline-block;
  //
  font-family: "Lato", sans-serif;
  padding-bottom: 2.2px;
  border-bottom: 2px solid $colorPrimary;
  margin-right: 1rem;

  &__label {
    white-space: nowrap;
    padding: 10px;
    padding-left: 2px;
    font-family: $tituloFonte;
    font-size: $tituloTamanho;
    color: $tituloCor;
    font-weight: 400;
    letter-spacing: 2px;
  }

  &__wrapper {
    &--input {
      border: none;
      outline: none;
      width: 100%;
      height: 2.5rem;

      display: flex;
      align-items: center;

      border-radius: 3px;
      padding: 3px 0 0 5px;
      font-family: $textoFonteInput;
      font-size: $textoTamanhoInput;
      color: $textoCorInput;
    }

    &--textarea {
      margin: 0px -10px 0px 0px;
      height: 152px;
      width: 100%;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      font-weight: 900;
      color: black;
    }
  }

  .react-datetime-picker {
    width: 100%;
  }

  .react-datetime-picker__wrapper {
    border: none;
  }

  .react-datetime-picker__clear-button {
    outline: none;
  }

  .react-datetime-picker__inputGroup {
    padding-left: 10px;
  }

  .react-datetime-picker__inputGroup__year {
    outline: none;
  }
  .react-datetime-picker__inputGroup__month {
    outline: none;
  }
  .react-datetime-picker__inputGroup__day {
    outline: none;
  }
  .react-datetime-picker__button:enabled:hover
    .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus
    .react-datetime-picker__button__icon {
    stroke: #ce2c2c;
  }

  .react-datetime-picker__inputGroup__input:invalid {
    background: none;
  }
}
