.menuInferiorContainer {
  // width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 1.5rem 18px 1.5rem !important;

  & .aba {
    font-size: $menuInferiorNaoSelecionadoTamanho;
    font-family: $menuInferiorNaoSelecionadoFonte;
    font-weight: $menuInferiorNaoSelecionadoPeso;
    color: $menuInferiorNaoSelecionadoCor;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: $menuInferiorSelecionadoCor;
    }
  }

  & .selected span {
    color: $menuInferiorSelecionadoCor;
    padding-bottom: 1rem;
    border-bottom: 4px solid $colorPrimary;
  }
}
