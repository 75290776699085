.integradorToggleContainer {
  display: flex;
  flex-direction: column;
  height: 43rem;
  justify-content: space-evenly;

  &__box {
    display: flex;
    justify-content: space-evenly;
    padding: 2rem;
    width: 620px;
    margin: 0 auto;
    border-radius: 8px;

    h1,
    p {
      text-align: center;
    }

    h1 {
      font-size: 16pt;
    }

    p {
      font-size: 11pt;
      margin-top: 12px;
      padding: 0px 60px;
      line-height: 21px;
    }

    .react-toggle {
      margin-top: 23px;
    }
  }
}

span[style="color:blue;"] {
  background: red;
}
