.title {
  padding: 10px;
  padding-left: 2px;
  font-weight: 400;
  letter-spacing: 2px;

  font-family: $tituloFonte;
  font-size: $tituloTamanho;
  color: $tituloCor;
}

.dd_wrapper {
  width: 100%;

  position: relative;
  display: block;
  font-family: "Lato", sans-serif;

  .react-select__control--is-disabled {
    
    .css-b8ldur-Input {
      visibility: hidden !important;
    }

    .react-select__placeholder {
      color: #000 !important;
    }
  }

  .react-select__control {
    background-color: transparent;
    border: 2px solid $colorPrimary;
    &--is-focused {
      box-shadow: 0 0 0 1px $colorPrimary;
      border: 3px solid $colorPrimary;
    }

    &:hover {
      background-color: transparent;
      border: 3px solid $colorPrimary;
    }
  }

  .react-select__option {
    background-color: $backgroundSelectNaoSelecionado;
    font-family: $textoFonteSelectNaoSelecionado;
    font-size: $textoTamanhoSelectNaoSelecionado;
    color: $textoCorSelectNaoSelecionado;

    &--is-focused {
      background-color: $backgroundSelectSelecionadoFocado;
      font-family: $textoFonteSelectSelecionado;
      font-size: $textoTamanhoSelectSelecionado;
      color: $textoCorSelectSelecionado;
    }
    &--is-selected {
      background-color: $backgroundSelectSelecionado;
      font-family: $textoFonteSelectSelecionado;
      font-size: $textoTamanhoSelectSelecionado;
      color: $textoCorSelectSelecionado;
    }
    &:hover {
      background-color: $backgroundSelectSelecionado;
      font-family: $textoFonteSelectSelecionado;
      font-size: $textoTamanhoSelectSelecionado;
      color: $textoCorSelectSelecionado;
    }
  }

  .react-select__clear-indicator {
    :hover {
      color: red;
    }
  }

  .react-select__indicator-separator {
    visibility: hidden;
  }
  .react-select__dropdown-indicator {
    color: $colorPrimary;
    :hover {
      color: $colorPrimary;
    }
  }

  .react-select__menu {
    height: 30px;
  }
}
